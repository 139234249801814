<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-07-12 14:55:30
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-21 17:34:35
-->
<template>
  <div class="">
    <ColumnarPage ref="ColumnarPage" :id="'classification'" :option="chart"></ColumnarPage>
  </div>
</template>

<script>
import ColumnarPage from './highcharts/ColumnarPage.vue'

export default {
  components: {
    ColumnarPage,
  },

  name: '',

  data() {
    return {
      chart: {
        chart: {
          type: 'column',
        },
        title: {
          text: '分类回款',
        },
        xAxis: {
          categories: [
            '一月',
            '二月',
            '三月',
            '四月',
            '五月',
            '六月',
            '七月',
            '八月',
            '九月',
            '十月',
            '十一月',
            '十二月',
          ],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: '分类回款 (万元)',
          },
        },
        tooltip: {
          // head + 每个 point + footer 拼接成完整的 table
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} 万元</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            borderWidth: 0,
          },
        },
        credits: {
          enabled: false,
        },
        series: [
          // {
          //   name: '商用空调',
          //   data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
          // },
          // {
          //   name: '空调',
          //   data: [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5, 106.6, 92.3],
          // },
          // {
          //   name: '中央空调',
          //   data: [48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3, 51.2],
          // },
          // {
          //   name: '家用电梯',
          //   data: [42.4, 33.2, 34.5, 39.7, 52.6, 75.5, 57.4, 60.4, 47.6, 39.1, 46.8, 51.1],
          // },
          // {
          //   name: '新风系统',
          //   data: [46.4, 40.2, 54.5, 56.7, 29.6, 27.5, 46.4, 38.4, 62.6, 27.1, 61.8, 36.1],
          // },
        ],
      },
    }
  },

  computed: {},

  created() {
    this.getData()
  },

  methods: {

    // 获取趋势图数据
    getData() {
      this.axios.get(`/api/product/category/categoryStatistics`).then(res => {
          if (res.code === 200) {
            let records = res.body
            for(let i=0;i<records.length;i++){
              let list = []
                this.chartList = [
                  records[i].january,
                  records[i].february,
                  records[i].march,
                  records[i].april,
                  records[i].may,
                  records[i].june,
                  records[i].july,
                  records[i].august,
                  records[i].september,
                  records[i].october,
                  records[i].november,
                  records[i].december,
                ]
              list = {
                name : records[i].title,
                data : this.chartList,
              }
              this.chart.series.push(list)
            }
            this.$refs.ColumnarPage.getCharts()
          }
        })
        .finally(() => (this.tableLoading = false))
    },

  },
}
</script>

<style lang='scss' scoped>
</style>